.FeedbackUI-stack {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 24px;
  right: auto;
  left: 24px;
}

.FeedbackUI-snackbar.FeedbackUI-snackbar {
  position: static;
}

.FeedbackUI-snackbar.FeedbackUI-snackbar:not(:last-child) {
  margin-bottom: 16px;
}
